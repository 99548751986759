import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import FootBar from "../components/navigations/FootBar";
import MiddleOne from "../components/navigations/MiddleOne";
import {Link} from "gatsby";
import NosServices from "../components/layout/NosServices";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import {getOurSolutions} from "../utils/Common";

const SeproSolution = () => {
  const {t} = useTranslation();
  const solutions = getOurSolutions();
  return (
      <Layout pageTitle={t("sepro_solution")}>
        <div className="min-h-full bg-gray-50">
          <NavBar active={'solution'}/>
          <div className="px-4 bg-sepro-solution-1">
            <div className="relative flex flex-col items-start max-w-7xl px-6 py-14 mx-auto text-left lg:px-0 font-family-title text-justify">
              <h1 className="max-w-2xl text-3xl font-extrabold tracking-tight text-white lg:text-6xl uppercase">{t("sepro")}</h1>
              <h1 className="max-w-2xl text-3xl font-extrabold tracking-tight text-white lg:text-6xl uppercase">{t("solution")}</h1>
              <p className="max-w-md text-sm md:text-base text-gray-400 text-justify mb-8 font-family-p">{t("sepro_solution_msg_1")}</p>
            </div>
          </div>
          <div className="-mt-16 mb-26">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-none lg:px-8">
              {/* Pourquoi KAYLOO*/}
              <div className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
                {solutions.map((solution, index) => (
                    <div className="grid grid-cols-1 mb-5">
                      {/* KAYLOO */}
                      <section aria-labelledby="kayloo-title">
                        <div className="bg-transparent overflow-hidden justify-center text-center">
                          <div className="p-0">
                            <div aria-hidden="true">
                              <img data-tip={t(solution.name)}
                                   src={"solutions/"+solution.image}
                                   alt={t(solution.name)}
                                   className="object-cover object-center w-full h-52"
                              />
                            </div>
                          </div>
                          <h1 className="max-w-2xl text-1xl font-bold text-gray-900 lg:text-2xl py-4 text-justify">{t(solution.name)}</h1>
                          <p className="max-w-full text-sm md:text-base text-gray-400 text-justify truncate" data-tip={t(solution.text)}>{t(solution.text)}</p>
                          <Link
                              to={solution.href}
                              className="inline-flex items-center justify-center px-3 py-3 mt-10 text-sm md:text-base font-medium text-white bg-cyan-900 border border-transparent shadow-sm whitespace-nowrap hover:bg-teal-900 uppercase"
                          >
                            <span className="mr-4">{t("read_more")}</span> <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9302 1L18.0002 7.07L11.9302 13.14" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path opacity="0.4" d="M1 7.07001H17.83" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          </Link>
                        </div>
                      </section>
                    </div>
                ))}

              </div>
            </div>
          </div>

          <div className="px-4 bg-sepro-solution-2">
            <div
                className="relative flex flex-col items-center max-w-2xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
              <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5xl uppercase">{t("our")}</h1>
              <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5xl uppercase">{t("services")}</h1>
              <p className="max-w-2xl mt-10 text-sm md:text-base text-gray-400 text-justify mb-20">{t("sepro_solution_msg_2")}</p>
            </div>
          </div>
          <div className="mb-36">
            <div className="bg-middle py-8 md:py-0 md:p-16">
              {/* Main 3 column grid */}
              <NosServices/>
            </div>
          </div>
          <MiddleOne/>

          <FootBar active={'solution'}/>
        </div>
        <ReactTooltip />
      </Layout>
  );
}

export default SeproSolution;
